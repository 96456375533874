import React from "react"
import dynamic from "next/dynamic"
import { uid } from "uid"
import { useInView } from "react-cool-inview";

import SEOHead from "../src/components/layout/head"

import { getData } from "../graphql/home"

const HeroInnerContent = dynamic(() => import("../src/components/hero/hero-inner-content"))
const ModalComponent = dynamic(() => import("../src/components/modals/modal-component/modal-component"), { ssr: false })
const ImageTriangle = dynamic(() => import("../src/components/blocks/image-triangle-div"), { ssr: true })
const Promotion = dynamic(() => import("../src/components/blocks/promotion"), { ssr: true, })
const PromotionBlock = dynamic(() => import("../src/components/blocks/promotion-block"), { ssr: true })
const ImageTextBlockTwoBlocks = dynamic(() => import("../src/components/blocks/image-text-two-blocks"), { ssr: true })
const ProductCarouselCollection = dynamic(() => import("../src/components/products/product-carousel-collection"), { ssr: true })
const FindLocationBar = dynamic(() => import("../src/components/location/find-location-bar"), { ssr: true })

export async function getStaticProps() {
  let previewVar = false
  if (process.env.NEXT_PUBLIC_CF_PREVIEW) {
    previewVar = process.env.NEXT_PUBLIC_CF_PREVIEW
  }

  const home =
    (await getData({
      preview: previewVar,
      locale: `en-${process.env.NEXT_PUBLIC_COUNTRY.toUpperCase()}`,
    })) ?? []

  return {
    props: {
      page: home.data.page,
    },
    revalidate: 60,
  }
}

function Home({ page }) {

  const { observe, inView } = useInView({
    threshold: 0, // Default is 0
    rootMargin: "50px 0px",
    onEnter: ({ unobserve }) => unobserve(),
  });

  return (
    <>
      <SEOHead
        title={
          page.items[0].seoMetadata ? page.items[0].seoMetadata.seoTitle : null
        }
        description={
          page.items[0].seoMetadata
            ? page.items[0].seoMetadata.description
            : null
        }
        keywords={
          page.items[0].seoMetadata ? page.items[0].seoMetadata.keywords : null
        }
        image={
          page.items[0].seoMetadata ? page.items[0].seoMetadata.image : null
        }
        noindex={page.items[0].seoMetadata ? page.items[0].seoMetadata.noindex : null}
        nofollow={page.items[0].seoMetadata ? page.items[0].seoMetadata.nofollow : null}
      />
      <React.StrictMode>
        <section ref={observe}>
          <HeroInnerContent
            desktopImage={
              page.items[0].hero.cover.image
                ? page.items[0].hero.cover.image.url
                : null
            }
            mobileImage={
              page.items[0].hero.cover.mobileImage
                ? page.items[0].hero.cover.mobileImage.url
                : null
            }
            classHero="text-center bg-white"
            firstTitleTag="h1"
            firstTitle={
              page.items[0].hero.header ? page.items[0].hero.header : null
            }
            classFirstTitle="mb-4"
            firstTitleBackground="bg-neon-red-400"
            firstTitleColor="text-white"
            firstTitleRotated
            secondTitleTag="h2"
            secondTitle={
              page.items[0].hero.subtitle ? page.items[0].hero.subtitle : null
            }
            classSecondTitle="mb-4 lg:mb-0 font-nandos text-3xl"
            secondTitleBackground=""
            secondTitleColor="text-white"
            secondTitleRotated={false}
            center
            content={
              page.items[0].hero.content ? page.items[0].hero.content : null
            }
            buttons={
              page.items[0].hero.buttonsCollection
                ? page.items[0].hero.buttonsCollection.items
                : null
            }
            theme="light"
            overlay={page.items[0].hero.enableOverlay}
            id={page.items[0].hero.videoId || null}
            source={page.items[0].hero.source || null}
            videoLoopId={page.items[0].hero.loopVideoId || null}
            videoLoopFile={
              page.items[0].hero.loopVideoFile
                ? page.items[0].hero.loopVideoFile.url
                : null
            }
            videoLoopSource={page.items[0].hero.loopVideoSource || null}
            popupPlayer={page.items[0].hero.popupPlayer}
            backgroundColor={page.items[0].hero.backgroundColor}
          />
          {page.items && page.items[0] && page.items[0].blocks.items && (
            <>
              {page.items[0].blocks.items.map((block, index) => (
                <React.Fragment key={uid(16)}>
                  {block && block.blockType === "Promotions" && inView && (
                    <React.StrictMode>
                      {block.blockTriangle ? (
                        <ImageTriangle
                          blockWidthClasses="w-full"
                          classList="py-0"
                          title={block.name}
                          content={block.description}
                          background="chillis"
                          titleTag="h3"
                          titleClassList="font-nandos text-xxl mb-0"
                          cover={block.cover}
                          alignContent="Center"
                          buttons={
                            block.cta || block.multipleCtaCollection.items
                          }
                          coverPosition={block.coverPosition}
                          classColumnsContent="lg:flex-row lg:pl-0"
                          maxWidthContent
                          widthDesktopImg={643}
                          heightDesktopImg={453}
                          widthMobileImg={375}
                          heightMobileImg={500}
                          layoutImgDesktop="fill"
                          layoutImgMobile="responsive"
                          textContentAlign="Center"
                          paddingContent="lg:pl-0"
                          classesContent="font-noto text-sm max-w-300px mx-auto xl:max-w-500px"
                          contentWidthClasses="lg:relative absolute lg:pt-10 w-full lg:w-1/2 h-500px lg:h-650px"
                          marginTriangle="lg:mt-0 md:mt-20 sm:mt-48 mt-60"
                          contentAlignTop
                          customPaddingY="pb-16 pt-6 sm:pt-16"
                          customPaddingX="px-4 sm:px-16"
                          id={block.videoId || null}
                          source={block.source || null}
                          videoLoopId={block.loopVideoId || null}
                          videoLoopFile={
                            block.loopVideoFile ? block.loopVideoFile.url : null
                          }
                          videoLoopSource={block.loopVideoSource || null}
                          popupPlayer={block.popupPlayer}
                          backgroundColor={block.backgroundColor}
                        />
                      ) : (
                        <Promotion
                          blockWidthClasses="w-full"
                          classList="py-0 bg-cream-100"
                          title={block.name}
                          content={block.description}
                          titleTag="h2"
                          titleClassList="font-nandos text-xxl mb-0"
                          cover={block.cover}
                          alignContent={index < 1 ? "Center" : "Left"}
                          buttons={
                            block.cta || block.multipleCtaCollection.items
                          }
                          coverPosition={block.coverPosition}
                          backgroundSize={block.backgroundSize}
                          classColumnsContent="lg:flex-row"
                          maxWidthContent
                          widthDesktopImg={643}
                          heightDesktopImg={453}
                          widthMobileImg={375}
                          heightMobileImg={264}
                          layoutImgDesktop="fill"
                          layoutImgMobile="responsive"
                          textContentAlign={index < 1 ? "Center" : "Left"}
                          flameHome={index < 1}
                          titleUnderlined="true"
                          lineWidth="w-1/3"
                          paddingContentHome={
                            index < 1 ? "lg:pt-64 lg:pb-24 pt-48" : ""
                          }
                          classesContent="font-noto text-sm"
                          promoId={block.promoId || null}
                          id={block.videoId || null}
                          source={block.source || null}
                          videoLoopId={block.loopVideoId || null}
                          videoLoopFile={
                            block.loopVideoFile ? block.loopVideoFile.url : null
                          }
                          videoLoopSource={block.loopVideoSource || null}
                          popupPlayer={block.popupPlayer}
                          contentClasses={index > 1 ? "lg:pl-10" : "pl-0"}
                          triangleHome={block.addTriangle}
                          backgroundColor={block.backgroundColor}
                        />
                      )}
                    </React.StrictMode>
                  )}

                  {block && block.blockType === "ImageTextBlock" && inView && (
                    <PromotionBlock
                      blockWidthClasses="w-full lg:h-700px"
                      classList="py-0 lg:pt-16 pt-20"
                      title={block.header}
                      content={block.content}
                      lineWidth="w-100px"
                      pattern={false}
                      titleClassList="font-nandos text-xxl mb-0 text-center"
                      patternClassList="text-metal-700 -bottom-28 lg:-bottom-52 top-auto -left-8 w-40 lg:w-64 transform -scale-x-1 rotate-2"
                      background="chillis"
                      titleTag="h2"
                      cover={block.cover}
                      alignContent="Center Mobile"
                      buttons={block.cta.items}
                      coverPosition={block.coverPosition}
                      backgroundSize={block.backgroundSize}
                      classColumnsContent="flex-col-reverse lg:flex-row"
                      maxWidthContent
                      widthDesktopImg={643}
                      heightDesktopImg={453}
                      widthMobileImg={375}
                      heightMobileImg={264}
                      layoutImgDesktop="fill"
                      layoutImgMobile="responsive"
                      backgroundColor={block.backgroundColor}
                      textContentAlign="Center Mobile"
                      classesContent="font-noto text-sm"
                      buttonsAlign="Center Mobile"
                      id={block.videoId || null}
                      source={block.source || null}
                      videoLoopId={block.loopVideoId || null}
                      videoLoopFile={
                        block.loopVideoFile ? block.loopVideoFile.url : null
                      }
                      videoLoopSource={block.loopVideoSource || null}
                      popupPlayer={block.popupPlayer}
                    />
                  )}

                  {block && block.blockType === "ImageTextBlockTwoBlocks" && inView && (
                    <React.StrictMode>
                      {block && (
                        <article className="w-full px-0 max-w-1400px mx-auto">
                          <div className="flex items-stretch flex-wrap">
                            {block.blocks.items.map((smallBlock, i) => (
                              <ImageTextBlockTwoBlocks
                                key={uid(16)}
                                blockWidthClasses="w-full lg:w-1/2"
                                classList="py-0"
                                cover={smallBlock ? smallBlock.cover : null}
                                title={smallBlock.header}
                                titleTag="h5"
                                alignContent="Center Mobile"
                                content={smallBlock.content || ""}
                                titleClassList="font-nandos text-xxl2 mb-0"
                                titleUnderlined
                                lineWidth="w-90px"
                                coverPosition={smallBlock.coverPosition}
                                buttons={smallBlock.cta.items}
                                classContainer={`${
                                  i % 2
                                    ? "flex-col-reverse md:flex-row-reverse lg:flex-row"
                                    : "flex-col-reverse md:flex-row lg:flex-row"
                                }`}
                                backgroundColor={block.backgroundColor}
                                widthDesktopImg={322}
                                heightDesktopImg={471}
                                widthMobileImg={375}
                                heightMobileImg={492}
                                layoutImgDesktop="fill"
                                layoutImgMobile="responsive"
                                classWidthContent="max-w-230px"
                                textContentAlign="Center Mobile"
                                id={smallBlock.videoId || null}
                                source={smallBlock.source || null}
                                videoLoopId={smallBlock.loopVideoId || null}
                                videoLoopFile={
                                  smallBlock.loopVideoFile
                                    ? smallBlock.loopVideoFile.url
                                    : null
                                }
                                videoLoopSource={
                                  smallBlock.loopVideoSource || null
                                }
                                popupPlayer={smallBlock.popupPlayer}
                              />
                            ))}
                          </div>
                        </article>
                      )}
                    </React.StrictMode>
                  )}
                  {block && block.blockType === "MenuInfoCategoryBlock" && inView && (
                    <ProductCarouselCollection
                      collection={block || null}
                      classList="bg-cream-100 pt-20 pb-0 lg:pb-8 w-full"
                      title={block ? block.header : null}
                      content={block ? block.description : null}
                      alignContent="Center Mobile"
                      buttonHome={
                        block.cta || block.multipleCtaCollection.items
                      }
                      classesButton="px-6"
                    />
                  )}
                </React.Fragment>
              ))}
            </>
          )}
          {inView && (
            <FindLocationBar classList="bg-cream-100" />
          )}
        </section>

        {page && page.items[0].popup ? (
          <ModalComponent popup={page.items[0].popup} slug="/" />
        ) : null}
      </React.StrictMode>
    </>
  )
}

export default Home