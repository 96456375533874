/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-absolute-path */
import React from "react"
import Head from "next/head"
import { useRouter } from 'next/router'

function SEOHead({ title, description, image, keywords, canonical, nofollow, noindex }) {
  const router = useRouter()
  const baseUrl = process.env.NEXT_PUBLIC_URL
  
  return (
    <Head>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      {title && <title>{title}</title>}
      <meta name="viewport" content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no" />
      <meta name="theme-color" content="#000000" />
      <link rel="manifest" href="/manifest.json" />
      {description && <meta name="description" content={description} />}
      {keywords && <meta name="keywords" content={keywords} />}
      <meta property="og:type" content="website" />
      {title && <meta property="og:title" content={title} />}
      {description && <meta property="og:description" content={description} />}
      {image ? (
        <meta property="og:image" content={image.url} />
      ) : (
        <meta property="og:image" content="https://images.ctfassets.net/xlzobf9ybr6d/Lk7q0ZhEgeOf99kM7LcOe/dd06ed144fe30f32df85ae332286cf98/generic-seo-image.jpg" />
      )}
      <meta property="og:url" content="permalink" />
      <link rel="shortcut icon" href="/icons/favicon.ico" />
      <link rel="apple-touch-icon" href="/icons/apple-touch-icon.png" />
      <link
        rel="canonical"
        href={`${
          process.env.NEXT_PUBLIC_COUNTRY === "us"
            ? `https://www.nandosperiperi.com${router.asPath.replace(/\/$/, '')}`
            : `https://www.nandos.ca${router.asPath.replace(/\/$/, '')}`
        }`}
      />
      {
        router.route === '/'
        ?
        <>
          <link
            rel="alternate"
            hrefLang="x-default"
            href={`${
              process.env.NEXT_PUBLIC_COUNTRY === "us"
                ? `https://www.nandosperiperi.com`
                : `https://www.nandos.ca`
            }`}
          />
          <link
            rel="alternate"
            hrefLang="en-GB"
            href="https://www.nandos.co.uk"
          />
          <link
            rel="alternate"
            hrefLang="en-IE"
            href="https://www.nandos.ie"
          />
        </>
        : ''
      }
       <link
        rel="alternate"
        hrefLang={`${process.env.NEXT_PUBLIC_COUNTRY === "us" ? "en-US" : "en-CA"}`}
        href={`${
          process.env.NEXT_PUBLIC_COUNTRY === "us"
            ? `https://www.nandosperiperi.com${router.asPath.replace(/\/$/, '')}`
            : `https://www.nandos.ca${router.asPath.replace(/\/$/, '')}`
        }`}
      />
      {
        router.route === '/find/[slug]'
        ? ''
        :
        <link
          rel="alternate"
          hrefLang={`${process.env.NEXT_PUBLIC_COUNTRY === "ca" ? "en-US" : "en-CA"}`}
          href={`${
            process.env.NEXT_PUBLIC_COUNTRY === "ca"
              ? `https://www.nandosperiperi.com${router.asPath.replace(/\/$/, '')}`
              : `https://www.nandos.ca${router.asPath.replace(/\/$/, '')}`
          }`}
        />
      }
      {canonical && <link rel="canonical" href={`${canonical}/`} />}

      {/* TWITTER CARDS */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:site"
        content={`${
          process.env.NEXT_PUBLIC_COUNTRY === "us"
            ? "@NandosUSA"
            : "@NandosPeriPeri"
        }`}
      />
      {title && <meta name="twitter:title" content={title} />}
      {description && (
        <meta property="twitter:description" content={description} />
      )}
      {image ? (
        <meta name="twitter:image" content={image.url} />
      ) : (
        <meta name="twitter:image" content="https://images.ctfassets.net/xlzobf9ybr6d/Lk7q0ZhEgeOf99kM7LcOe/dd06ed144fe30f32df85ae332286cf98/generic-seo-image.jpg" />
      )}
      {noindex && !nofollow ? (
        <meta name="robots" content="noindex"/>
      ) : null }
      {nofollow && !noindex ? (
        <meta name="robots" content="nofollow"/>
      ) : null }
      {nofollow && noindex ? (
        <meta name="robots" content="noindex,nofollow"/>
      ) : null }
    </Head>
  )
}

export default SEOHead
